import React from "react";

const Create = (props) => {
	return (
		<svg
			{...props}
			width="32"
			height="32"
			viewBox="0 0 24 24"
			fill="transparent"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z"
				fill="var(--light-accent-color)"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H13V7Z"
				fill="var(--light-accent-color)"
			/>
		</svg>
	);
};

const Login = (props) => {
	return (
		<svg
			{...props}
			width="36"
			height="36"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M15 12L11 8M15 12L11 16M15 12H5M10 21C11.1819 21 12.3522 20.7672 13.4442 20.3149C14.5361 19.8626 15.5282 19.1997 16.364 18.364C17.1997 17.5282 17.8626 16.5361 18.3149 15.4442C18.7672 14.3522 19 13.1819 19 12C19 10.8181 18.7672 9.64778 18.3149 8.55585C17.8626 7.46392 17.1997 6.47177 16.364 5.63604C15.5282 4.80031 14.5361 4.13738 13.4442 3.68508C12.3522 3.23279 11.1819 3 10 3"
				stroke="var(--light-accent-color)"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

const Delete = (props) => {
	return (
		<svg
			{...props}
			width="36"
			height="36"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 4C7.581 4 4 7.582 4 12C4 16.418 7.581 20 12 20C16.419 20 20 16.418 20 12C20 7.582 16.419 4 12 4ZM15.707 14.293C15.8945 14.4805 15.9998 14.7348 15.9998 15C15.9998 15.2652 15.8945 15.5195 15.707 15.707C15.5195 15.8945 15.2652 15.9998 15 15.9998C14.7348 15.9998 14.4805 15.8945 14.293 15.707L12 13.414L9.707 15.707C9.61435 15.8002 9.50419 15.8741 9.38285 15.9246C9.26152 15.9751 9.13141 16.001 9 16.001C8.86859 16.001 8.73848 15.9751 8.61715 15.9246C8.49581 15.8741 8.38565 15.8002 8.293 15.707C8.10553 15.5195 8.00021 15.2652 8.00021 15C8.00021 14.7348 8.10553 14.4805 8.293 14.293L10.586 12L8.293 9.707C8.10549 9.51949 8.00015 9.26518 8.00015 9C8.00015 8.73482 8.10549 8.48051 8.293 8.293C8.48051 8.10549 8.73482 8.00015 9 8.00015C9.26518 8.00015 9.51949 8.10549 9.707 8.293L12 10.586L14.293 8.293C14.4805 8.10549 14.7348 8.00015 15 8.00015C15.2652 8.00015 15.5195 8.10549 15.707 8.293C15.8945 8.48051 15.9998 8.73482 15.9998 9C15.9998 9.26518 15.8945 9.51949 15.707 9.707L13.414 12L15.707 14.293Z"
				fill="#CC393E"
			/>
		</svg>
	);
};

const Home = (props) => {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 15 15"
			fill="transparent"
		>
			<g clip-path="url(#clip0_39_6)">
				<path
					d="M7.825 0.120033C7.73443 0.0425682 7.61918 0 7.5 0C7.38082 0 7.26557 0.0425682 7.175 0.120033L0 6.27003V13.5C0 13.8979 0.158035 14.2794 0.43934 14.5607C0.720644 14.842 1.10218 15 1.5 15H5.5C5.63261 15 5.75979 14.9474 5.85355 14.8536C5.94732 14.7598 6 14.6326 6 14.5V11.5C6 11.1022 6.15804 10.7207 6.43934 10.4394C6.72064 10.1581 7.10218 10 7.5 10C7.89782 10 8.27936 10.1581 8.56066 10.4394C8.84196 10.7207 9 11.1022 9 11.5V14.5C9 14.6326 9.05268 14.7598 9.14645 14.8536C9.24021 14.9474 9.36739 15 9.5 15H13.5C13.8978 15 14.2794 14.842 14.5607 14.5607C14.842 14.2794 15 13.8979 15 13.5V6.27003L7.825 0.120033Z"
					fill="var(--light-accent-color)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_39_6">
					<rect
						width="15"
						height="15"
						fill="var(--light-accent-color)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

const Icon = { Create, Login, Delete, Home };

export default Icon;
